import React from 'react';

export function StarIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg
            aria-hidden
            width='22'
            height='22'
            viewBox='0 0 22 22'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <path
                d='M11.83 1.53932C11.6888 1.21115 11.3762 1 11.0315 1C10.6868 1 10.3742 1.21115 10.233 1.53932L7.76751 7.27149L1.78117 7.94694C1.43844 7.98561 1.14939 8.2306 1.04287 8.5727C0.936344 8.91481 1.03219 9.29027 1.28767 9.53183L5.75025 13.7499L4.51599 19.8995C4.44533 20.2516 4.57931 20.6142 4.85819 20.8256C5.13708 21.037 5.50892 21.0579 5.80797 20.879L11.0315 17.7538L16.2551 20.879C16.5541 21.0579 16.9259 21.037 17.2049 20.8256C17.4837 20.6142 17.6177 20.2516 17.547 19.8995L16.3127 13.7499L20.7753 9.53183C21.0308 9.29027 21.1266 8.91481 21.0201 8.5727C20.9136 8.2306 20.6246 7.98561 20.2819 7.94694L14.2955 7.27149L11.83 1.53932Z'
                fill='currentColor'
                stroke='currentColor'
            />
        </svg>
    );
}
