import React from 'react';
import { IconButton, Grid } from '@mui/material';
import { ratingSelectStyles as classes } from './styleObjects/RatingSelect';
import { StarIcon } from '@components/IconComponents/StarIcon';
import { HalfStarIcon } from '@components//IconComponents/HalfStarIcon';
import { EmptyStarIcon } from '@components//IconComponents/EmptyStarIcon';
import { v2Colors } from '@web-for-marketing/react-ui';

interface RatingSelectProps {
    rating?: number;
    starColor?: string;
    starSize?: number;
    totalStars?: number;
    clickable?: boolean;
    setRating?: (rating: number) => void;
}

export function RatingSelect({
    setRating,
    rating = 1,
    totalStars = 5,
    clickable = false,
    starColor = v2Colors.text.activePrimary,
    starSize = 24,
}: RatingSelectProps): JSX.Element {
    const renderStar = (index: number): JSX.Element => {
        const isFullStar = index + 1 <= Math.floor(rating);
        const isHalfStar = !isFullStar && index + 0.5 <= rating;

        return (
            <React.Fragment key={index}>
                {clickable && setRating ? (
                    <IconButton onClick={() => setRating(index + 1)} size='large'>
                        {isFullStar ? (
                            <StarIcon css={classes.star(starSize)} color={starColor} data-testid='star-icon' />
                        ) : isHalfStar ? (
                            <HalfStarIcon css={classes.star(starSize)} color={starColor} data-testid='half-star-icon' />
                        ) : (
                            <EmptyStarIcon
                                css={classes.star(starSize)}
                                color={starColor}
                                data-testid='empty-star-icon'
                            />
                        )}
                    </IconButton>
                ) : (
                    <div style={{ display: 'inline-block', position: 'relative' }} css={classes.starContainer}>
                        {isFullStar ? (
                            <StarIcon css={classes.star(starSize)} color={starColor} data-testid='star-icon' />
                        ) : isHalfStar ? (
                            <HalfStarIcon css={classes.star(starSize)} color={starColor} data-testid='half-star-icon' />
                        ) : (
                            <EmptyStarIcon
                                css={classes.star(starSize)}
                                color={starColor}
                                data-testid='empty-star-icon'
                            />
                        )}
                    </div>
                )}
            </React.Fragment>
        );
    };

    return <Grid data-testid='starsContainer'>{Array.from(Array(totalStars), (_val, i) => renderStar(i))}</Grid>;
}
