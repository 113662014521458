export const ratingSelectStyles = {
    starContainer: {
        '&:not(:last-child)': {
            marginRight: '0.5rem',
        },
    },
    star: (size: number) => ({
        width: size ? `${size}px` : '24px',
        height: size ? `${size}px` : '24px',
    }),
} as const;
