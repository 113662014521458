import React from 'react';
import { PropTypes } from 'prop-types';

function checkAbbreviations(content, stringFormat) {
    let currentContent = null;

    if (content && content.length) {
        let abbreviations = content.split(/~!(.*?)!~/gm);
        currentContent = content;

        if (abbreviations.length > 1) {
            if (stringFormat) {
                currentContent = abbreviations
                    .map((element) => {
                        let newAbbrElement;

                        if (element.indexOf('{') > -1 && element.indexOf('}') > -1) {
                            let abbreviationContent = element.split(/{(.*?)}/gm);

                            newAbbrElement = abbreviationContent.length
                                ? `<abbr title=${abbreviationContent[0]}>${addBreaks(
                                    abbreviationContent[1],
                                    stringFormat
                                )}</abbr>`
                                : '';
                        } else {
                            newAbbrElement = addBreaks(element, stringFormat);
                        }

                        return newAbbrElement;
                    })
                    .join('');
            } else {
                currentContent = (
                    <>
                        {abbreviations.map((element, index) => {
                            let newAbbrElement;
                            if (element.indexOf('{') > -1 && element.indexOf('}') > -1) {
                                let abbreviationContent = element.split(/{(.*?)}/gm);

                                newAbbrElement = abbreviationContent.length ? (
                                    <abbr title={abbreviationContent[0]} key={element + index}>
                                        {addBreaks(abbreviationContent[1], stringFormat)}
                                    </abbr>
                                ) : null;
                            } else {
                                newAbbrElement = addBreaks(element, stringFormat, index);
                            }
                            return newAbbrElement;
                        })}
                    </>
                );
            }
        } else {
            currentContent = addBreaks(currentContent, stringFormat);
        }
    }
    return currentContent;
}

function addBreaks(content, stringFormat, index = 0) {
    const contentWithNewline = content.replace(/<br>|<br \/>/gm, '\n');
    let splitContent = contentWithNewline.split('\n');
    let contentWithBreaks = [];

    if (splitContent.length > 1) {
        if (stringFormat) {
            for (let i = 0; i < splitContent.length - 1; i++) {
                contentWithBreaks.push(`${splitContent[i]}<br />`);
            }
            contentWithBreaks.push(splitContent[splitContent.length - 1]);
            splitContent = contentWithBreaks;
        } else {
            for (let i = 0; i < splitContent.length - 1; i++) {
                contentWithBreaks.push(
                    <React.Fragment key={i + splitContent[i]}>
                        {splitContent[i]}
                        <br />
                    </React.Fragment>
                );
            }
            contentWithBreaks.push(
                <React.Fragment key={splitContent[index]}>{splitContent[splitContent.length - 1]}</React.Fragment>
            );
            splitContent = <React.Fragment key={splitContent.length - 1}>{contentWithBreaks}</React.Fragment>;
        }
    } else {
        if (!stringFormat) {
            splitContent = splitContent[0];
        }
    }

    return stringFormat ? splitContent.join('') : splitContent;
}

export function ParseAbbreviations({ content, stringFormat }) {
    return checkAbbreviations(content, stringFormat);
}

ParseAbbreviations.defaultProps = {
    content: '',
};

ParseAbbreviations.propTypes = {
    content: PropTypes.string,
    stringFormat: PropTypes.bool,
};
